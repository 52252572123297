import axios from "axios";

class ListsService {
  getAll() {
    return this.client().get("/lists");
  }

  get(list_name) {
    return this.client().get(`/lists/${list_name}`);
  }

  create(data) {
    return this.client().post("/lists", data);
  }

  update(id, data) {
    return this.client().put(`/lists/${id}`, data);
  }

  delete(id) {
    return this.client().delete(`/lists/${id}`);
  }

  deleteAll() {
    return this.client().delete(`/lists`);
  }

  findByTitle(title) {
    return this.client().get(`/lists?title=${title}`);
  }

  client() {
    return axios.create({
      baseURL: "https://ticks-backend.flatedges.xyz",
      headers: {
        "Content-type": "application/json"
      }
    });
  }
}

export default new ListsService();
