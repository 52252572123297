import React, { Component, Fragment }  from 'react';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import {
  Paper
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import InputBase from '@material-ui/core/InputBase';
import TasksService from '../services/TasksService';

const styles = theme => ({
  root: {
    padding: '2px 4px',
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    width: 300,
  },
  input: {
    marginLeft: theme.spacing(1),
    length: 3,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 2,
  },
});

class ListForm extends Component {
  constructor (props, context) {
    super(props, context);
    this.state = {
      list_name: null
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({
      list_name: event.target.value
    })
  }


  handleSubmit(event) {
    var list_name = this.state.list_name;
    this.saveList(list_name)
    event.preventDefault();
  }

  saveList(list_name) {
    var task = {
      list_name: list_name,
      task_name: "To do"
    }
    TasksService.create(task).then(
      response => {
        this.props.addList(task)
        this.setState({
          list_name: ''
        })
      }
    )
    .catch(
      e => {
        console.log(e);
      }
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Paper component="form" className={classes.root} id="list-form">

          <InputBase
            className={classes.input}
            placeholder={"New list"}
            inputProps={{ 'aria-label': 'add list' }}
            onChange={this.handleChange}
            value={this.state.list_name || ''}
            autoFocus
            required
          />
          <Divider className={classes.divider} orientation="vertical" />
          <IconButton
            color="primary"
            className={classes.iconButton}
            aria-label="add"
            type="submit"
            form="list-form"
            onSubmit={this.handleSubmit}
            onClick={this.handleSubmit}
          >
            <SendIcon />
          </IconButton>
        </Paper>
      </Fragment>
    );
  }
}
export default withStyles(styles)(ListForm)
