import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  Paper,
} from '@material-ui/core';
import SidebarDrawer from '../components/SidebarDrawer';
import TaskForm from '../components/TaskForm';
import TaskList from '../components/TaskList';
import SidebarStateHandler from '../helpers/SidebarStateHandler';
import ListsService from '../services/ListsService';
import TasksService from '../services/TasksService';
import clsx from 'clsx';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Link } from 'react-router-dom';
import ListItemText from '@material-ui/core/ListItemText';

const drawerWidth = 200;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
});

class Lists extends Component {

  constructor (props, context) {
    super(props, context);
    this.state = {
      loading: true,
      tasks: [],
      completed_tasks: [],
      list_name: props.match.params.list_name,
      sidebar_state_handler: new SidebarStateHandler(),
    }
  }


  componentDidMount() {
    if(this.props.match.params.list_name) {
      this.getTasksForList(this.state.list_name);
    }
  }

  componentDidUpdate(prev_props, prev_state) {
    if(this.props.match.params.list_name !== prev_props.match.params.list_name) {
      this.getTasksForList(this.props.match.params.list_name);
    }
    return true;
  }

  splitTasksBetweenCompleteAndNot(list, property) {
    return list.reduce(function(memo, x) {
      if (!memo[x[property]]) { memo[x[property]] = []; }
      memo[x[property]].push(x);
      return memo;
    }, {});
  }

  getTasksForList(list_name) {
    ListsService.get(list_name).then(
      response => {
        var split_tasks = this.splitTasksBetweenCompleteAndNot(response.data, 'completed');
        this.setState({
          loading: false,
          tasks: split_tasks.false || [],
          completed_tasks: split_tasks.true || [],
        });
      }
    )
    .catch(
      e => {
        console.log(e);
      }
    );
  }

  completeTask(id, data) {
    TasksService.complete(id, data).then(
      response => {
        return;
      }
    )
    .catch(
      e => {
        console.log(e);
      }
    );
  }

  uncompleteTask(id, data) {
    TasksService.uncomplete(id, data).then(
      response => {
        return;
      }
    )
    .catch(
      e => {
        console.log(e);
      }
    );
  }

  addTask = (task) => {
    var tasks = this.state.tasks;
    tasks.push(task);
    this.setState({
      tasks: tasks
    });
  }

  handleDrawerClose = () => {
    this.setState({
      open: this.state.sidebar_state_handler.handleDrawerClose()
    })
    return this.state.open;
  }

  handleDrawerOpen = () => {
    this.setState({
      open: this.state.sidebar_state_handler.handleDrawerOpen()
    })
    return this.state.open;
  }

  openState = () => {
    return this.state.sidebar_state_handler.openState()
  }

  renderSidebar = () => {
    return <SidebarDrawer
             openHandle={this.handleDrawerOpen}
             closeHandle={this.handleDrawerClose}
             openState={this.openState}
           />
  }

  findTaskFromId = (id) => {
    var foundTask = null
    foundTask = this.state.tasks.filter(task => {
      return task.id === id
    })[0]
    if(foundTask) {
      return foundTask
    }
    return this.state.completed_tasks.filter(task => {
      return task.id === id
    })[0]
  }

  removeElementFromArray = (id, arr) => {
    for( var i = 0; i < arr.length; i++) {
      if(arr[i].id === id) {
        arr.splice(i,1);
      }
    }
    return arr;
  }

  complete = (id) => {
    var task = this.findTaskFromId(id);
    this.completeTask(id, task)
    var new_completed_tasks = this.state.completed_tasks
    new_completed_tasks.push(task)
    this.setState({
      completed_tasks: new_completed_tasks,
      tasks: this.removeElementFromArray(id, this.state.tasks)
    })
  }

  uncomplete = (id) => {
    var task = this.findTaskFromId(id);
    this.uncompleteTask(id, task)
    var new_tasks = this.state.tasks
    new_tasks.push(task)
    this.setState({
      completed_tasks: this.removeElementFromArray(id, this.state.completed_tasks),
      tasks: new_tasks
    })
  }


  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <CssBaseline />
        {this.renderSidebar()}
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: this.openState(),
          })}
        >
          <div className={classes.drawerHeader} />
          {this.props.match.params.list_name ? (
            <Typography variant="h4" gutterBottom>
              {this.props.match.params.list_name}
            </Typography>
          ) : ("") }
          <TaskForm list_name={this.props.match.params.list_name} addTask={this.addTask}/>
          <Typography variant="h6" gutterBottom>
            To do
          </Typography>
          <Paper className={classes.paper}>
          {this.state.tasks.length > 0 ? (
            <TaskList handleToggle={this.complete} tasks={this.state.tasks} completed={false} />
          ) : (
            <List>
              <ListItem button to='#' disabled={true}>
                <ListItemText primary={"No tasks to display"}/>
              </ListItem>
            </List>
          )}
          </Paper>
          <Typography variant="h6" gutterBottom>
            Completed
          </Typography>
          <Paper className={classes.paper}>
          {this.state.completed_tasks.length > 0 ? (
            <TaskList handleToggle={this.uncomplete} tasks={this.state.completed_tasks} completed={true} />
          ) : (
            <List>
              <ListItem button to='#' disabled={true}>
                <ListItemText primary={"No completed tasks to display"}/>
              </ListItem>
            </List>
          )}
          </Paper>
        </main>
      </div>
    );
  }
}

export default withStyles(styles)(Lists)
