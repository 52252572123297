import React, { Component, Fragment }  from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListIcon from '@material-ui/icons/List';
import WorkIcon from '@material-ui/icons/Work';
import HomeIcon from '@material-ui/icons/Home';
import ListsService from '../services/ListsService';
import { Link } from 'react-router-dom';
import ListForm from './ListForm';

const drawerWidth = 200;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
});

class SidebarDrawer extends Component {
  constructor (props, context) {
    super(props, context);
    this.state = {
      loading: true,
      open: true,
      lists: []
    }
  }

  iconForText(text) {
    if(text.toLowerCase() === 'personal') {
      return <HomeIcon />;
    }
    else if (text.toLowerCase() === 'work') {
      return <WorkIcon />;
    }
    else {
      return <ListIcon />;
    }
  }

  componentDidMount() {
    this.getLists();
  }

  initCapListName(list_name) {
    return list_name.charAt(0).toUpperCase() + list_name.slice(1);
  }

  getLists() {
    ListsService.getAll().then(
      response => {
        this.setState({
          lists: response.data
        });
      }
    )
    .catch(
      e => {
        console.log(e);
      }
    );
  }

  addList = (list) => {
    var lists = this.state.lists;
    lists.push(list);
    this.setState({
      lists: lists
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: this.props.openState(),
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => this.props.openHandle()}
              edge="start"
              className={clsx(classes.menuButton, this.props.openState() && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              Ticks
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={this.props.openState()}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={() => this.props.closeHandle()}>
              {classes.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider />
        {this.state.lists.length > 0 ? (
          <Fragment>
            <List>
              {this.state.lists.map(list => (
                <ListItem key={list.list_name} button component={Link} to={`/lists/${list.list_name}`}>
                  <ListItemIcon>{this.iconForText(list.list_name)}</ListItemIcon>

                  <ListItemText primary={this.initCapListName(list.list_name)} />
                </ListItem>
              ))}
              <Divider />
              <ListItem to='#'>
                <ListForm addList={this.addList}/>
              </ListItem>
            </List>
          </Fragment>
        ) : (
          !this.state.loading && <Typography variant="subtitle1">No lists to display</Typography>
        )}
        </Drawer>
      </Fragment>
    );
  }
}
export default withStyles(styles)(SidebarDrawer)
