import React, { Component, Fragment }  from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {
  ListItemSecondaryAction,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';

const styles = theme => ({
  strikethrough: {
    textDecoration: 'line-through'
  }
});

class TaskList extends Component {
  constructor (props, context) {
    super(props, context);
    this.state = {}
  }

  classForListItem(classes) {
    if(this.props.completed === false) {
      return '';
    }
    else {
      return classes.strikethrough;
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <List>
          {this.props.tasks.map(task => (
            <ListItem key={task.id} button component={Link} to='#'>
              <ListItemText id={task.id} primary={task.task_name} className={this.classForListItem(classes)}/>
              <ListItemSecondaryAction>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={this.props.completed ? (true) : (false)}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': task.id }}
                    onClick={() => this.props.handleToggle(task.id)}
                  />
                </ListItemIcon>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Fragment>
    );
  }
}
export default withStyles(styles)(TaskList)
