import React, { Component }  from 'react';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import {
  Paper
} from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import InputBase from '@material-ui/core/InputBase';
import TasksService from '../services/TasksService';

const styles = theme => ({
  root: {
    padding: '2px 4px',
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    width: 300,
  },
  input: {
    marginLeft: theme.spacing(1),
    length: 3,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 2,
  },
});

class TaskForm extends Component {
  constructor (props, context) {
    super(props, context);
    this.state = {
      task_name: null,
      list_name: this.props.list_name
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prev_props, prev_state) {
    if(prev_props.list_name !== this.props.list_name) {
      this.setState({
        task_name: null,
        list_name: this.props.list_name
      })
    }
  }

  handleChange(event) {
    this.setState({
      task_name: event.target.value
    })
  }

  handleSubmit(event) {
    var task = this.state;
    this.saveTask(task)
    event.preventDefault();
  }

  saveTask(task) {
    task.list_name = this.props.list_name
    TasksService.create(this.state).then(
      response => {
        this.props.addTask(task)
        this.setState({
          task_name: ''
        })
      }
    )
    .catch(
      e => {
        console.log(e);
      }
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <Paper component="form" className={classes.root} id="task-form">
        <InputBase
          className={classes.input}
          placeholder={"Add task to " + this.props.list_name}
          inputProps={{ 'aria-label': 'add task' }}
          onChange={this.handleChange}
          value={this.state.task_name || ''}
          autoFocus
          required
        />
        <Divider className={classes.divider} orientation="vertical" />
        <IconButton
          color="primary"
          className={classes.iconButton}
          aria-label="add"
          type="submit"
          form="task-form"
          onSubmit={this.handleSubmit}
          onClick={this.handleSubmit}
        >
          <SendIcon />
        </IconButton>
      </Paper>
    );
  }
}
export default withStyles(styles)(TaskForm)
