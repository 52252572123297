/* global gapi */
import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './App.css';
import SignIn from "./pages/SignIn";
import Lists from "./pages/Lists";
import List from "./pages/List";
import Home from "./pages/Home";

const PrivateRoute = ({ component, ...options }) => {
  var finalComponent = SignIn;
  if(window.localStorage.getItem('ticks-auth')) {
    finalComponent = component;
  }
  return <Route {...options} component={finalComponent} />;

};

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSignedIn: false,
    }
  }

  requiresLogin(props, Component) {
    new Promise((resolve, reject) => {
      window.gapi.load('auth2', () => {
        this.auth2 = gapi.auth2.init({
          client_id: '64121688206-8qu6aps3hm4r1lffhjrl475cu0a0cv0f.apps.googleusercontent.com',
        })

        this.auth2.then(() => {
          return resolve(this.auth2.isSignedIn.get() ? <Component {...props}/> : <div>Need to login</div>)

        })
      })
    }).then(component => {
      return component
    })
  }

  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route path="/sign_in" component={SignIn}/>
            <PrivateRoute exact path="/lists" component={Lists}/>
            <PrivateRoute exact path="/lists/:list_name" component={List}/>
            <Route path="/" component={Home}/>
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
