import axios from "axios";
import UUIDGenerator from "../helpers/UUIDGenerator";

class TasksService {
  getAll() {
    return this.client().get("/tasks");
  }

  getTasksForList(list_name) {
    return this.client().get(`/tasks/${list_name}`);
  }

  create(data) {
    data["id"] = this.create_uuid()
    data["completed"] = false
    return this.client().post("/tasks", data);
  }

  update(id, data) {
    return this.client().put(`/tasks/${id}`, data);
  }

  complete(id, data) {
    data["completed"] = true
    return this.update(id, data)
  }

  uncomplete(id, data) {
    data["completed"] = false
    return this.update(id, data)
  }

  delete(id) {
    return this.client().delete(`/tasks/${id}`);
  }

  create_uuid() {
    return new UUIDGenerator().create_uuid()
  }

  client() {
    return axios.create({
      baseURL: "https://ticks-backend.flatedges.xyz",
      headers: {
        "Content-type": "application/json",
      }
    });
  }
}

export default new TasksService();
