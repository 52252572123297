class SidebarStateHandler {
  constructor() {
    this.open = true
  }

  handleDrawerOpen = () => {
    this.open = true
  };

  handleDrawerClose = () => {
    this.open = false
  };

  openState = () => {
    return this.open;
  }
}
export default SidebarStateHandler;
