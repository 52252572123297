import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import SidebarDrawer from '../components/SidebarDrawer';
import SidebarStateHandler from '../helpers/SidebarStateHandler';

class Lists extends Component {
  constructor (props, context) {
    super(props, context);
    this.state = {
      sidebar_state_handler: new SidebarStateHandler(),
    }
  }

  handleDrawerClose = () => {
    this.setState({
      open: this.state.sidebar_state_handler.handleDrawerClose()
    })
    return this.state.open;
  }

  handleDrawerOpen = () => {
    this.setState({
      open: this.state.sidebar_state_handler.handleDrawerOpen()
    })
    return this.state.open;
  }

  openState = () => {
    return this.state.sidebar_state_handler.openState()
  }

  renderSidebar = () => {
    return <SidebarDrawer
             openHandle={this.handleDrawerOpen}
             closeHandle={this.handleDrawerClose}
             openState={this.openState}
           />
  }

  render() {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        {this.renderSidebar()}
      </Container>
    );
  }
}

export default Lists
